/* eslint-disable @nx/enforce-module-boundaries */
import styles from './contact-us-form.module.scss';

import { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { GoldButton } from '../button/button';
import { useTranslation } from 'react-i18next';
import { Loader, SelectDropdown, Spinner } from '@orascom/common-components';
import { Country as CountryUtilities } from '../../../utils/country.utils';
import { Contact as ContactUtilities } from '../../../utils/contact.utils';
import { OptionValue } from '@orascom/api-interfaces';
import { Country } from '../../../definitions/interfaces/country.interface';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorIcon from '../../../assets/icons/error.svg';
import successIcon from '../../../assets/icons/success.svg';
import errorSubmitIcon from '../../../assets/icons/error-2.svg';
import {
  ContactTimeEnum,
  ContactTypeEnum,
  CustomerInquiryEnum,
  FormInitialData,
  PortalPageEnum,
  ReferralSharingWayStatus,
  SubmitLeadData,
} from '../../../definitions/interfaces/contact.interface';
import { USER_CONTEXT } from 'apps/orascom-shopper-app/src/contexts/user-context';

import { useGetMyUnits } from 'apps/orascom-shopper-app/src/pages/pre-delivery/hooks/use-get-my-units';
import {
  analyticsContactUsCustomEvent,
  analyticsTalkToUsCustomEvent,
} from 'apps/orascom-shopper-app/src/utils/analytics-events';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  CommonEventParameters,
  formatSearchParams,
  validatePhone,
} from '@orascom/utils';

export interface ContactUsFormProps {
  title?: string;
  destination?: string;
  country?: number;
  countryName?: string;
  interestForm?: boolean;
  referralForm?: boolean;
  countryPage?: boolean;
  investmentPage?: boolean;
  unitId?: number;
  contactUsPage?: boolean;
  onCloseModal?: () => void;
  setFormSubmission?: (arg: boolean) => void;
  doneButtonAsLink?: boolean;
  doneButtonRoute?: string;
  talkToSalesForm?: boolean;
}

export function ContactUsForm(props: Readonly<ContactUsFormProps>) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [countries, setCountries] = useState<Country[]>([]);
  const [countriesOptions, setCountriesOptions] = useState<OptionValue[]>([]);
  const [destinations, setDestinations] = useState<OptionValue[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<OptionValue>();
  const [successMsg, setSuccessMsg] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [referredDestinationName, setReferredDestinationName] =
    useState<string>();
  const [myUnitsOptions, setMyUnitsOptions] = useState<OptionValue[]>([]);
  const [showAdditionalMessage, setShowAdditionalMessage] =
    useState<boolean>(false);
  const userContext = useContext(USER_CONTEXT);
  const user = userContext.user;
  const [myUnits, loadingMyUnits] = useGetMyUnits();

  const utmSource = localStorage.getItem('utm_source');
  const utmMedium = localStorage.getItem('utm_medium');
  const utmCampaign = localStorage.getItem('utm_campaign');

  const stringValidationRegex = /^(?!\s+$)/;

  const requestForValidation = props.contactUsPage && {
    requestFor: Yup.object().nullable().required(t('pleaseSelectReasonFor')),
  };
  const shareUnitValidation = props.referralForm && {
    shareUnit: Yup.object().nullable(),
  };

  const destinationValidation = !props.interestForm && {
    destination: Yup.object().nullable().required(t('required')),
  };

  const ContactUsFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(25)
      .required(t('pleaseEnterFullName'))
      .matches(stringValidationRegex, t('nameWhiteSpacesError')),
    lastName: Yup.string()
      .max(25)
      .required(t('pleaseEnterFullName'))
      .matches(stringValidationRegex, t('nameWhiteSpacesError')),
    email: Yup.string().email().required(t('pleaseEnterValidEmail')),
    phoneNumber: Yup.string().required(t('pleaseEnterPhoneNumber')),
    ...requestForValidation,
    ...shareUnitValidation,
    countryCode: Yup.string().required(),
    message: Yup.string().max(250, t('maximumCharacters250')),
    contactType: Yup.object().nullable().required(t('required')),
    contactTime: Yup.object().nullable().required(t('required')),
    ...destinationValidation,
    // ...shareDestinationValidation,
  });

  const getCountryDestinations = (
    countriesValues: Country[],
    countryId: number
  ) => {
    const selectedCountryDestinations = countriesValues
      .filter((country) => countryId === country.id)
      .flatMap((country) => country.destinations)
      .map((destination) => {
        return {
          label: destination.name,
          value: destination.slug,
        };
      });

    setDestinations(selectedCountryDestinations as OptionValue[]);
  };

  useEffect(() => {
    CountryUtilities.getCountries().then((res) => {
      const countriesOptionsMap = res.map((country) => {
        return {
          label: country.name,
          value: country.id,
        };
      });
      setCountries(res);
      setCountriesOptions(countriesOptionsMap);

      if (props.countryPage && props.country) {
        getCountryDestinations(res, props.country);
      }
    });
  }, []);

  useEffect(() => {
    if (myUnits) {
      const myUnitsOptionsMap = myUnits.map((unit) => {
        return {
          label: unit.name,
          value: unit.id,
        };
      });
      setMyUnitsOptions(myUnitsOptionsMap);
    }
  }, [myUnits]);

  useEffect(() => {
    if (selectedCountries) {
      getCountryDestinations(countries, Number(selectedCountries.value));
    }
  }, [selectedCountries]);

  const handleSubmitForm = (
    formData: FormInitialData,
    resetForm: () => void
  ) => {
    const interestedInUnitIdOptions = (data: FormInitialData) =>
      data.unitId ? data.unitId?.value.toString() : '';

    const destinationSlugOptions = (data: FormInitialData) =>
      data.destination ? data.destination?.value.toString() : '';

    const referralFormOrTalkToSalesPage = props.referralForm
      ? PortalPageEnum.REFERRAL_PRE_DELIVERY
      : PortalPageEnum.TALK_TO_SALES;

    const interestFormOrReferralFormOrTalkToSalesPage = props.interestForm
      ? PortalPageEnum.COMPARE_FILLED_PAGE
      : referralFormOrTalkToSalesPage;

    const portalPageOptions = props.destination
      ? PortalPageEnum.DESTINATION_PAGE
      : interestFormOrReferralFormOrTalkToSalesPage;

    const customerInquiryOptions = (data: FormInitialData) =>
      props.unitId || data.unitId
        ? CustomerInquiryEnum.UNIT_PURCHASING
        : CustomerInquiryEnum.GENERAL;

    const mappedData: SubmitLeadData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: '+' + formData.countryCode + formData.phoneNumber,
      mobile_country_code: formData.countryCode,
      country: props.country ?? Number(formData.country?.value),
      ...(!props.referralForm && {
        interested_in_unit_id: props.unitId
          ? props.unitId.toString()
          : interestedInUnitIdOptions(formData),
      }),
      destination_slug: props.destination
        ? props.destination.toString()
        : destinationSlugOptions(formData),
      preferred_communication_method: formData.contactType?.value
        ? String(formData.contactType?.value)
        : '',

      preferred_communication_time: formData.contactTime?.value
        ? String(formData.contactTime?.value)
        : '',
      portal_comments: `${formData.message} ${
        formData.requestFor?.value ? String(formData.requestFor?.value) : ''
      } ${formData.shareUnit?.value ? String(formData.shareUnit?.value) : ''}`,
      is_predelivery: props.referralForm ?? false,
      portal_page: props.unitId ? PortalPageEnum.UNIT_PAGE : portalPageOptions,

      customer_inquiry: props.investmentPage
        ? CustomerInquiryEnum.INVESTMENT
        : customerInquiryOptions(formData),
      ...(props.referralForm
        ? {
            referral_unit_name: String(formData.unitId?.label),
            referral_customer: user?.phone,
            referral_customer_name: user?.name,
            referral_unit_destination: referredDestinationName,
            referral_way_to_share_unit:
              formData.wayToShareUnit?.value ??
              Number(formData.wayToShareUnit?.value),
          }
        : {}),
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    };
    ContactUtilities.submitShopperLead(mappedData)
      .then(() => {
        setSuccessMsg('lead created successfully');
        props.setFormSubmission?.(true);

        const commonEventParams: CommonEventParameters = {
          userId: user?.id.toString(),
          timestamp: Date.now().toString(),
          portal: 'Shopper',
          pageName: location.pathname,
        };

        if (props.talkToSalesForm) {
          analyticsTalkToUsCustomEvent(commonEventParams);
        } else {
          analyticsContactUsCustomEvent(commonEventParams);
        }

        resetForm();
      })
      .catch((error) => setErrorMsg(error));
  };

  if (errorMsg?.length) {
    return (
      <div>
        <div
          className={`${styles['error']} ${
            props.contactUsPage ? styles['contact-page'] : ''
          }`}
        >
          <p className={styles['error-title']}>
            <img src={errorSubmitIcon} role="presentation" alt="" />
            <span>{t('formSubmitErrorTitle')}</span>
          </p>
          <p className={styles['error-message']}>
            {t('formSubmitErrorMessage')}
          </p>
        </div>
        <GoldButton onClick={() => setErrorMsg('')}>
          {t('Try again')}
        </GoldButton>
      </div>
    );
  }
  if (successMsg?.length) {
    return (
      <div>
        <div
          className={`${styles['success']} ${
            props.contactUsPage ? styles['contact-page'] : ''
          }`}
        >
          <p className={styles['success-title']}>
            <img src={successIcon} role="presentation" alt="" />
            <span>{t('formSubmitSuccessTitle')}</span>
          </p>
          <p
            className={`${styles['success-message']} ${
              props.contactUsPage ? styles['success-message'] : ''
            }`}
          >
            {t('formSubmitSuccessMessage')}
          </p>
        </div>
        <GoldButton onClick={() => setSuccessMsg('')}>
          {t('Submit another request')}
        </GoldButton>
      </div>
    );
  }

  if (props.referralForm && loadingMyUnits) {
    return <Loader />;
  }

  const firstNameLabel = !props.referralForm
    ? t('firstName')
    : t('referral’sFirstName');

  const lastNameLabel = !props.referralForm
    ? t('lastName')
    : t('referral’sLastName');

  const emailLabel = !props.referralForm
    ? `${t('email')}*`
    : `${t('referral’sEmailAddress')}*`;

  const countryLabel = !props.referralForm
    ? t('countryInterestedIn')
    : t('countryTheyInterestedIn');

  const destinationLabel = !props.referralForm
    ? t('destinationInterestedIn')
    : t('destinationTheyInterestedIn');

  return (
    <Formik
      initialValues={{
        firstName: user ? user.name.substring(0, user.name.indexOf(' ')) : '',
        lastName: user ? user.name.substring(user.name.indexOf(' ') + 1) : '',
        email: user ? user.email : '',
        phoneNumber: user ? user.parsed_phone.national_phone : '',
        countryCode: user ? user.parsed_phone.country_code.toString() : '20',
        ...(!props.interestForm && { country: null }),
        ...(!props.interestForm && { destination: null }),
        ...(props.contactUsPage && { requestFor: null }),
        ...(props.referralForm && { shareUnit: null }),
        ...(props.referralForm && { referredDestination: '' }),
        ...(!props.unitId && { unitId: null }),
        contactType: null,
        contactTime: null,
        wayToShareUnit: null,
        message: searchParams
          ? formatSearchParams(searchParams.toString())
          : '',
      }}
      validationSchema={ContactUsFormSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmitForm(values, resetForm);
      }}
    >
      {({ errors, touched, setFieldValue, values, isSubmitting }) =>
        !isSubmitting ? (
          <Form>
            {props.title && <legend>{props.title}</legend>}
            <div className={props.talkToSalesForm ? 'inputs-wrapper' : ''}>
              <div className="input-wrapper">
                <label htmlFor="firstName">{firstNameLabel}*</label>
                <Field
                  name="firstName"
                  type="text"
                  className={
                    errors.firstName && touched.firstName ? 'error' : ''
                  }
                />

                <ErrorMessage
                  name="firstName"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {msg}
                    </p>
                  )}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="lastName">{lastNameLabel}*</label>
                <Field
                  name="lastName"
                  type="text"
                  className={errors.lastName && touched.lastName ? 'error' : ''}
                />

                <ErrorMessage
                  name="lastName"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {msg}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="input-wrapper">
              <label htmlFor="email">{emailLabel}</label>
              <Field
                name="email"
                type="email"
                className={errors.email && touched.email ? 'error' : ''}
                placeholder="example@example"
              />

              <ErrorMessage
                name="email"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {msg}
                  </p>
                )}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="phoneNumber">{t('phoneNumber')}*</label>
              <div className={styles['phone-wrapper']}>
                <PhoneInput
                  countryCodeEditable={false}
                  inputProps={{
                    name: 'countryCode',
                  }}
                  country={'eg'}
                  onChange={(phone) => {
                    setFieldValue('countryCode', phone);
                    setFieldValue('phoneNumber', '');
                  }}
                  enableSearch={true}
                  excludeCountries={['il']}
                  value={values.countryCode}
                />
                <Field
                  name="phoneNumber"
                  type="tel"
                  validate={() => {
                    validatePhone(values.phoneNumber, values.countryCode);
                  }}
                  className={
                    errors.phoneNumber && touched.phoneNumber ? 'error' : ''
                  }
                  placeholder="123 456 7890"
                />
              </div>
              <ErrorMessage
                name="phoneNumber"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {msg}
                  </p>
                )}
              />
            </div>
            {props.referralForm && (
              <div className="input-wrapper">
                <label htmlFor="unitId">{`${t('referredUnit')} ${t(
                  'optional'
                )}`}</label>
                <SelectDropdown
                  name="unitId"
                  className={`${styles['select']} ${
                    errors.unitId && touched.unitId ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => {
                    setFieldValue('unitId', option);
                    setReferredDestinationName(
                      myUnits.find((unit) => option?.value === unit.id)?.project
                        .destination.name
                    );
                  }}
                  options={myUnitsOptions}
                  selectedOption={values.unitId ?? null}
                />
                <ErrorMessage
                  name="unitId"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {msg}
                    </p>
                  )}
                />
              </div>
            )}
            {props.contactUsPage && (
              <div className="input-wrapper">
                <label htmlFor="requestFor">{t('requestFor')}*</label>
                <SelectDropdown
                  name="requestFor"
                  className={`${styles['select']} ${
                    errors.requestFor && touched.requestFor ? 'error' : ''
                  }`}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => setFieldValue('requestFor', option)}
                  options={[
                    { value: 'sales', label: 'Sales' },
                    { value: 'inquiry', label: 'Inquiry' },
                  ]}
                  selectedOption={values.requestFor ?? null}
                />

                <ErrorMessage
                  name="requestFor"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {msg}
                    </p>
                  )}
                />
              </div>
            )}
            {!props.interestForm && !props.countryPage && (
              <div className="input-wrapper">
                <label htmlFor="country">{countryLabel}*</label>
                <SelectDropdown
                  name="country"
                  className={styles['select']}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => {
                    if (option) {
                      setSelectedCountries(option);
                      setFieldValue('country', option);
                      setFieldValue('destination', null);
                    }
                  }}
                  options={countriesOptions}
                  selectedOption={values.country ?? null}
                />

                <ErrorMessage
                  name="country"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {msg}
                    </p>
                  )}
                />
              </div>
            )}
            {!props.interestForm && (
              <div className="input-wrapper">
                <label htmlFor="destination">{destinationLabel}*</label>
                <SelectDropdown
                  name="destination"
                  className={styles['select']}
                  placeholder={t('selectPlaceholder')}
                  disabled={!selectedCountries && !props.countryPage}
                  onChange={(option) => setFieldValue('destination', option)}
                  options={destinations}
                  selectedOption={values.destination ?? null}
                />

                <ErrorMessage
                  name="destination"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {msg}
                    </p>
                  )}
                />
              </div>
            )}

            {props.referralForm ? (
              <div className="input-wrapper">
                <label htmlFor="wayToShareUnit">{t('wayToShareUnit')}</label>
                <SelectDropdown
                  name="wayToShareUnit"
                  className={styles['select']}
                  placeholder={t('selectPlaceholder')}
                  onChange={(option) => setFieldValue('wayToShareUnit', option)}
                  options={[
                    {
                      label: t('sendReservationLink'),
                      value: ReferralSharingWayStatus.RESERVATION_LINK,
                    },
                    {
                      label: t('contactingSales'),
                      value: ReferralSharingWayStatus.CONTACTING_WITH_SALES,
                    },
                  ]}
                  selectedOption={values.wayToShareUnit ?? null}
                />

                <ErrorMessage
                  name="wayToShareUnit"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {msg}
                    </p>
                  )}
                />
              </div>
            ) : null}

            <div className="input-wrapper">
              <label htmlFor="contactType">
                {props.referralForm
                  ? t('contactTypeReffered')
                  : t('contactType')}
                *
              </label>
              <SelectDropdown
                name="contactType"
                className={`${styles['select']} ${
                  errors.contactType && touched.contactType ? 'error' : ''
                }`}
                placeholder={t('selectPlaceholder')}
                onChange={(option) => setFieldValue('contactType', option)}
                options={[
                  { value: ContactTypeEnum.MOBILE, label: 'Phone call' },
                  { value: ContactTypeEnum.WHATS_APP, label: 'Whatsapp' },
                  { value: ContactTypeEnum.EMAIL, label: 'Email' },
                  {
                    value: ContactTypeEnum.VIRTUAL_MEETING,
                    label: 'virtual meeting',
                  },
                ]}
                selectedOption={values.contactType ?? null}
              />

              <ErrorMessage
                name="contactType"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {msg}
                  </p>
                )}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="contactTime">
                {props.referralForm
                  ? t('contactTimeReffered')
                  : t('contactTime')}
                *
              </label>
              <SelectDropdown
                name="contactTime"
                className={`${styles['select']} ${
                  errors.contactTime && touched.contactTime ? 'error' : ''
                }`}
                placeholder={t('selectPlaceholder')}
                onChange={(option) => setFieldValue('contactTime', option)}
                options={[
                  { value: ContactTimeEnum.MORNING, label: 'Morning' },
                  { value: ContactTimeEnum.AFTERNOON, label: 'Afternoon' },
                  { value: ContactTimeEnum.NIGHT, label: 'Night' },
                ]}
                selectedOption={values.contactTime ?? null}
              />

              <ErrorMessage
                name="contactTime"
                render={(msg: string) => (
                  <p className="error">
                    <img src={ErrorIcon} role="presentation" alt="" />
                    {msg}
                  </p>
                )}
              />
            </div>

            {props.talkToSalesForm && !showAdditionalMessage ? (
              <button
                className="anchor"
                onClick={() => setShowAdditionalMessage(true)}
              >
                {t('addAdditionalMessage')}
              </button>
            ) : null}
            {!props.talkToSalesForm || showAdditionalMessage ? (
              <div className="input-wrapper">
                <label htmlFor="message">{`${t('message')} ${t(
                  'optional'
                )}`}</label>
                <Field
                  name="message"
                  as="textarea"
                  rows="6"
                  className={errors.message && touched.message ? 'error' : ''}
                  placeholder={t('messagePlaceHolder')}
                />

                <ErrorMessage
                  name="message"
                  render={(msg: string) => (
                    <p className="error">
                      <img src={ErrorIcon} role="presentation" alt="" />
                      {msg}
                    </p>
                  )}
                />
              </div>
            ) : null}
            <p className={styles['disclaimer']}>{t('contactUsDisclaimer')}</p>
            <div
              className={
                props.talkToSalesForm ? styles['contact-btn__wrapper'] : ''
              }
            >
              <GoldButton
                className={styles['contact-btn']}
                type="submit"
                disabled={isSubmitting}
              >
                {!props.referralForm
                  ? t('sendRequest')
                  : t('submitReferralInfo')}
              </GoldButton>
            </div>
          </Form>
        ) : (
          <div className={styles['submitting']}>
            <Spinner />
            <p>{t('sendingRequest')}</p>
          </div>
        )
      }
    </Formik>
  );
}

export default ContactUsForm;
